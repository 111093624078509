import "./Player_Registration.css"
import { Base_Props } from "../App/App";
import { useForm } from "react-hook-form"
import { Client_Event_Name } from "../../../common/src/state/Event_And_State_Names";
import { Standard_Page_Layout } from "../Components/Standard_Page_Layout";

type FormValues = {
    player_name: string
    game_code: string
}
  
export function Player_Registration(props: Base_Props) {

    const { send } = props
    const { register, handleSubmit, formState: { errors }, } = useForm<FormValues>()
    const onSubmit = handleSubmit((data) => send({ type: Client_Event_Name.register_player, detail: data}))
    const cancel_registration = () => {
        window.location.hash = ''
        history.back()
    }
    let join_code = ''
    if (window.location.hash && window.location.hash.slice(1).length === 4) {
        join_code = window.location.hash.slice(1);
    }

    const header = <>
        <div className="page_header">Join</div>
        <div className="sub_header">Join a game as a player</div>
    </>

    const content = <>
        <form id="register_player_form" name="register_player" onSubmit={onSubmit}>
            <span>
                <div className="form_row">
                    <label htmlFor="player_reg_name">Name</label>
                    <input id="player_reg_name" className={errors.player_name ? "validation_warn" : ""} {...register("player_name", { required: true, maxLength: 20 })} placeholder="your player name" />
                    {errors?.player_name && <p>{errors.player_name.message}</p>}
                </div>
                <div className="form_row">
                    <label htmlFor="player_reg_code">Code</label>
                    <input className={errors.game_code ? "validation_warn uppercase" : "uppercase"} id="player_reg_code" {...register("game_code", { required: true, maxLength: 20 })} defaultValue={join_code} placeholder="game code" />
                    {errors?.game_code && <p>{errors.game_code.message}</p>}
                </div>
            </span>
        </form>
    </>

    const controls = <>
        <button type="submit" form="register_player_form">join game</button>
        <button className="negative" onClick={cancel_registration}>cancel</button>
    </>

    return (
        <Standard_Page_Layout
            header={header}
            content={content}
            controls={controls} />
    )
}