import "./Host_Registration.css"
import { Client_Event_Name } from "../../../common/src/state/Event_And_State_Names"
import { Base_Props } from "../App/App"
import { Controller, useForm } from "react-hook-form"
import { Standard_Page_Layout } from "../Components/Standard_Page_Layout"
import { Modal_Focus } from "../Components/Modal_Focus/Modal_Focus"
import "react-toggle/style.css"
import Toggle from 'react-toggle'

type FormValues = {
	game_name: string,
	host_email: string,
	teams_enabled: boolean,
	questions_and_rounds_enabled: boolean,
}
  
export function Host_Registration(props: Base_Props) {

	const { context, send } = props
	const { register, control, handleSubmit, formState: { errors }, } = useForm<FormValues>()
	const onSubmit = handleSubmit((data) => send({ type: context.is_trial ? Client_Event_Name.register_trial_host : Client_Event_Name.register_host, detail: { is_trial: context.is_trial, ...data}}))

	const header = <>
		<div className="page_header">Create game</div>
		<div className="sub_header">lets get you started</div>
	</>
	const content = <>
		<form id="host_reg_form" onSubmit={onSubmit}>
			<div className="form_row">
				<Modal_Focus center={true}>
					<input className={errors.game_name ? "validation_warn" : ""} id="host_reg_form_game_name" placeholder="enter a game name"
						{...register("game_name", { 
							required: { value: true, message: 'game name is required' },
							maxLength: { value: 20, message: 'game name is too long, max 20' }
						})}  />
					<div className="show_in_modal validation_error">{errors.game_name?.message}</div>
				</Modal_Focus>
			</div>
			<div className="form_row capsule">
			<label htmlFor='is_trial_toggle'>Trial game<br /><span className="mini_subtext">10 min limit</span></label>
			<Toggle
  				id='is_trial_toggle'
				icons={{
					checked: null,
					unchecked: null
				}}
				className="pink-toggle"
  				defaultChecked={!context.is_trial}
  				onChange={() => {send({ type: Client_Event_Name.toggle_trial })}} />
			<label className="right_text" htmlFor='is_trial_toggle'>Real game<br /><span className="mini_subtext">email required</span></label>
			</div>

			{context.is_trial ? <></> : 
				<>
					<div className="form_row">
					<Modal_Focus>
						<span>
							<label>Host email:</label> 
							<input className={errors.host_email ? "validation_warn" : ""} {...register("host_email", { 
								required: { value: !context.is_trial, message: 'required for verification' },
								maxLength: { value: 100, message: 'too long', }, 
								minLength: { value: 6, message: 'too short', },
								pattern: {
									value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
									message: 'invalid email',
								} 
							})} placeholder="enter your email" />
							<div className="show_in_modal validation_error">{errors.host_email?.message}</div>
						</span>
					</Modal_Focus>
					</div>
					
					
				</>
			}
			
			<h2>Game options</h2>
			<div className="form_row">
				<Controller 
					control={control}
					name="teams_enabled"
					render={({ field: {value, onChange } }) => 
						<Toggle
						id='host_reg_teams_enabled'
						icons={{
							checked: null,
							unchecked: null
						}}
						checked={value}
						className="pink-toggle"
						onChange={onChange} />
				}
				/>
				<label className="label_after" htmlFor="host_reg_teams_enabled">Teams</label>
			</div>
			<div className="form_row">
				<Controller 
					control={control}
					name="questions_and_rounds_enabled"
					render={({ field: {value, onChange } }) => 
						<Toggle
						id='host_reg_questions_and_rounds_enabled'
						icons={{
							checked: null,
							unchecked: null
						}}
						checked={value}
						className="pink-toggle"
						onChange={onChange} />
				}
				/>
				<label className="label_after" htmlFor="host_reg_questions_and_rounds_enabled">Questions and Rounds</label></div>
		</form>
		
	</>

	const controls = <>
		<button type="submit" form="host_reg_form">Start setup</button>
		<button className="negative" onClick={() => history.back()}>Cancel game</button>
	</>
	return (
		<Standard_Page_Layout
            header={header}
            content={content}
            controls={controls} />
	)
}