import { Component, ErrorInfo, ReactNode } from "react";

interface Error_Boundary_Props {
    children?: ReactNode
}

interface Error_Boundary_State {
    has_error: boolean,
    error_message: string
}

export class Error_Boundary extends Component<Error_Boundary_Props, Error_Boundary_State> {
 
    constructor() {
        super({});
        this.state = { has_error: false, error_message: '' };
    }

    public static getDerivedStateFromError(error: Error): Error_Boundary_State {
        // Update state so the next render will show the fallback UI.
        return { has_error: true, error_message: error.message };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
       // eslint-disable-next-line no-console
       console.error('Uncaught error:', error, errorInfo)
    }
 
    public render() {
       const { has_error, error_message } = this.state;
       const { children } = this.props
 
       if (has_error) {
          return (
             <div>Error in app: {error_message}</div>
          );
       }
 
       return children
    }
 }