import { Base_Props } from "../App/App"
  
export function Modal_Popup(props: Base_Props) {

	const { context } = props

	if (!context.modal_header) {
		return <></>
	}

	return (
		<div className="Modal_Popup">
			<div className="Modal_header">{context.modal_header}</div>
			<div className="Modal_content">{context.modal_content}</div>
			{context.modal_confirm_action && (<button onClick={context.modal_confirm_action}></button>)}
			{context.modal_cancel_action && (<button onClick={context.modal_cancel_action}></button>)}
		</div>
	)
}