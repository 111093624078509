import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'

export const Key_For_XState_Persistence = 'Key_For_XState_Persistence'

const storage_snapshot_string = sessionStorage.getItem(Key_For_XState_Persistence)
const storage_snapshot = storage_snapshot_string ? JSON.parse(storage_snapshot_string) : undefined;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App snapshot={storage_snapshot}/>
  </React.StrictMode>,
)
