import { AnyEventObject } from "xstate"
import { PlayerContext } from "./player_machine"

export function set_round_name_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, text } = props.event.detail
    props.context.game_structure.rounds[round_num].name = text
	return props.context.game_structure
}

export function set_question_text_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, question_index, text } = props.event.detail
    props.context.game_structure.rounds[round_num].questions[question_index].question_text = text
    return props.context.game_structure
}

export function set_answer_text_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, question_index, text } = props.event.detail
    props.context.game_structure.rounds[round_num].questions[question_index].answer_text = text
    return props.context.game_structure
}

export function set_points_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, question_index, points } = props.event.detail
    const new_points = parseInt(points);
    if (!isNaN(new_points)) {
		props.context.game_structure.rounds[round_num].questions[question_index].points = new_points
	}
	return props.context.game_structure
}

export function set_question_deduct_points(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, question_index, points } = props.event.detail
    const new_points = parseInt(points);
    if (!isNaN(new_points)) {
		props.context.game_structure.rounds[round_num].questions[question_index].incorrect_points = new_points
	}
	return props.context.game_structure
}

export function swap_quiz_questions_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_num, q_1_index, q_2_index } = props.event.detail
    const array = [...props.context.game_structure.rounds[round_num].questions]
	if (q_1_index >= 0 && q_1_index < array.length -1 && q_2_index > q_1_index && q_2_index < array.length) {
		const q1 = array[q_1_index]
		const q2 = array[q_2_index]
		array[q_1_index] = { ...q2}
		array[q_2_index] = { ...q1}
	}
	props.context.game_structure.rounds[round_num].questions = array
	return props.context.game_structure
}

export function swap_rounds_action(props: { event: AnyEventObject, context: PlayerContext }) {
    const { round_1_index, round_2_index } = props.event.detail
    const array = [...props.context.game_structure.rounds]

    if (round_1_index >= 0 && round_1_index < array.length -1 && round_2_index > round_1_index && round_2_index < array.length) {
		const q1 = array[round_1_index]
		const q2 = array[round_2_index]
		array[round_1_index] = { ...q2}
		array[round_2_index] = { ...q1}
	}
	props.context.game_structure.rounds = array
	return props.context.game_structure
}
