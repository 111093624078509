import { Client_Event_Name } from "../../../common/src/state/Event_And_State_Names"
import { Base_Props } from "../App/App"
import { Standard_Page_Layout } from "../Components/Standard_Page_Layout"

  
export function Host_Unverified(props: Base_Props) {

	const { send } = props

	const send_cancel_game = () => send({ type: Client_Event_Name.cancel_game})

	const header = <>
		<div className="page_header">Verify game</div>
		<div className="sub_header">check your email to verify the game</div>
	</>
	const content = <>
		verification email sent to {props.context.host_email}, click the verify link in the email and then return here to continue.
		<div className="hidden">
			<input data-testid='game_code' value={props.context.game_code} />
		</div>
	</>

	const controls = <>
		<button className='negative' onClick={send_cancel_game}>Cancel game</button>
	</>
	return (
		<Standard_Page_Layout
            header={header}
            content={content}
            controls={controls} />
	)
}