import { Base_Props } from "../App/App";
import { Client_Event_Name } from "../../../common/src/state/Event_And_State_Names";
import { Standard_Page_Layout } from "../Components/Standard_Page_Layout";


export function Player_Finished(props: Base_Props) {

    const { game_name } = props.context
	const { send } = props

	const send_quit = () => send({ type: Client_Event_Name.quit })

	const header = <>
		<div className="page_header">Finished</div>
		<div className="sub_header">congratulations to the winner of game: {game_name}!</div>
	</>
	const content = <>
		
	</>

	const controls = <>
		<button onClick={send_quit}>Exit</button>
	</>
	return (
		<Standard_Page_Layout
            header={header}
            content={content}
            controls={controls} />
	)

}
