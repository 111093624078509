import { Base_Props } from "../App/App";
import { useForm } from "react-hook-form"
import './Host_Game_Setup.css'
import { Client_Event_Name, Host_Event_Name } from "../../../common/src/state/Event_And_State_Names";
import { Standard_Page_Layout } from "../Components/Standard_Page_Layout";
import toast from "react-hot-toast";

type Add_Team_Form_Values = {
    team_name: string
    game_name: string
}

export function Host_Game_Setup(props: Base_Props) {

    const { game_name, game_code, teams, players, teams_enabled, questions_and_rounds_enabled } = props.context

    const { send } = props
    const { register, resetField, handleSubmit, formState: { errors }, } = useForm<Add_Team_Form_Values>()
    const onSubmit = handleSubmit((data) => {
        send({ type: Client_Event_Name.add_team, detail: data})
        resetField('team_name')
    })
    const remove_team = (team_name: string) => send({type: Host_Event_Name.remove_team, detail: { team_name }})

    const remove_player = (player_name: string) => send({type: Host_Event_Name.remove_player, detail: { player_name }})

    const start_game = () => send({type: Client_Event_Name.start_game})

    const link = window.location.protocol + "//" + window.location.host + `#${game_code.toUpperCase()}`

    const copy_link_to_clipboard = () => {
        navigator.clipboard.writeText(link)
        toast.success(`link copied to clipboard`, {
            position: 'top-center'
        })
    }

    const send_cancel_game = () => send({ type: Client_Event_Name.cancel_game})

    // generate a map of which teams players are in:
    const player_teams: { [index: string]: string } = {}
    teams.list.map(team => team.members.list.map(player => player_teams[player.name] = team.name));

    const generate_teams_list = () => {
        if (!teams_enabled) return <></>

        return teams.list.map(team => 
            <div className="team_container">
                <div className="team_left_side">
                    <div className="team_name">{team.name}</div>
                    <button className="negative mini_text" data-testid={`remove team ${team.name}`} title={`remove team ${team.name}`}  onClick={() => remove_team(team.name)}><span>remove<br />team</span></button>
                </div>
                <div className="team_right_side">
                    <div>Team members:</div>
                    <div className="team_players_box">{team.members.list.map(member => <div className="player_item" data-player-item={member.name}>{member.name}</div>)}</div>
                </div>
            </div>
            )}

    const header = <>
        <header className="page_header">{teams_enabled ? 'Set up teams' : 'Set up players'}</header>
        <header className="sub_header">game: {game_name}</header>
        <div className="hidden">
			<input data-testid='game_code' value={game_code} />
		</div>
    </>

    const content = <>
        <div className="host_game_setup">
            {teams_enabled ? 
                <div className="add_team_section">
                    <form onSubmit={onSubmit}>
                            <div className="">
                                <input className={errors?.team_name ? "validation_warn" : ""} {...register("team_name", { required: true, maxLength: 20 })} placeholder="new team name" />
                                <input {...register("game_name")} hidden value={game_name} />
                                <button type="submit">add team</button>
                            </div>
                    </form>
                    <h2>Teams</h2>
                    {generate_teams_list()}
                </div>
            : <></>
            }
            <div className="host_setup_players_section">
                
                <h2>Players</h2>
                {players.list.length === 0 ? 
                    <span>
                        <div>
                            <button className="icon_button" onClick={copy_link_to_clipboard}>
                                <svg fill="#000000" width="1.5em" height="1.5em" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
                                    <title>copy-to-clipboard-line</title>
                                    <path fill="currentColor" stroke="currentColor" d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z"></path>
                                    <path fill="currentColor" stroke="currentColor" d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"></path>
                                    <path fill="currentColor" stroke="currentColor" d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"></path>
                                    <path fill="currentColor" stroke="currentColor" d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"></path>
                                    <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                                </svg>
                                <div>Click here to copy the join link</div>
                            </button>
                            <input type="hidden" data-testId="join_game_link" value={link} />
                        </div>
                    </span>
                    :
                    <table className="host_player_list">
                        <thead><tr><th>Player</th><th>Team</th><th>Remove</th><th>Connected</th></tr></thead>
                        {players.list.length === 0 && <tr><td colSpan={3}></td></tr>}
                        <tbody>
                            {players.list.map(player => <tr>
                                <td><div className="player_item" data-player-item={player.name}>{player.name}</div></td>
                                <td>{player_teams[player.name] || '-'}</td>
                                <td><button onClick={() => remove_player(player.name)}>X</button></td>
                                <td><div className={player.connected ? 'socket_connected' : 'socket_disconnected'}></div></td>
                            </tr>)}
                        </tbody>
                    </table>
                }
            </div>
            
        </div>

    </>

    const controls = <>
        <button type="submit" onClick={start_game}>Start game</button>
        {questions_and_rounds_enabled ? <button className="alternative" onClick={() => send({type: Host_Event_Name.setup_game_structure})}>Back: setup questions</button> : <></>}
        <button className='negative' onClick={send_cancel_game}>Cancel game</button>
    </>


    return (
        <Standard_Page_Layout
            header={header}
            content={content}
            controls={controls}
        />
    )
}




