import { assign, sendTo, setup, type AnyEventObject } from 'xstate';
import { Websocket_Event_Names, websocketCallback } from './websockets_machine';
import { Map_And_List } from '../../../common/src/utils/map_and_sorted_list';
import { Player, Team } from '../../../common/src/models/Base_Concepts';
import { Client_Event_Name, Host_Event_Name, Server_Event_Name, Server_State_Name } from '../../../common/src/state/Event_And_State_Names';
import { Round_Based_Game_Structure } from '../../../common/src/models/Game_Structure';
import { set_answer_text_action, set_points_action, set_question_deduct_points, set_question_text_action, set_round_name_action, swap_quiz_questions_action, swap_rounds_action } from './game_structure_actions';
import { Game_Structure_Update_Event, Game_Update_Event, Host_Update_Event } from '../../../common/src/messages_from_server';
import { ReactElement } from 'react';
import toast from 'react-hot-toast';

export interface PlayerContext {
	player_name: string,
	game_name: string,
	is_trial: boolean,
	game_code: string,
	player_secret: string,
	host_secret: string,
	host_email: string,
	players: Map_And_List<Player>
	teams: Map_And_List<Team>
	whos_buzzer_is_active: string | undefined,
	game_structure: Round_Based_Game_Structure,
	teams_enabled: boolean,
	questions_and_rounds_enabled: boolean,
	round_num: number,
	question_num: number,
	modal_header?: string,
	modal_content?: ReactElement,
	modal_confirm_action?: () => void,
	modal_cancel_action?: () => void,
}

export interface EventReceiver {
	send: (event: Event) => void
}

function players_are_all_in_teams(context: PlayerContext) {
	if (context.teams_enabled) {
		const members_in_teams: string[] = []
		context.teams.list.map(team => team.members.list.map(member => members_in_teams.push(member.name)))
		const all_players: string[] = context.players.list.map(player => player.name)
		return members_in_teams.sort().join() === all_players.sort().join()
	}
	else return true
}

export enum Client_State_Name {
	splash = 'splash',
	registering_player = 'registering_player',
	connecting_player = 'connecting_player',
	player_game_setup = 'player_game_setup',
	player_in_game = 'player_in_game', 
	player_finished = 'player_finished',
	connecting_host = 'connecting_host',
	registering_host = 'registering_host',
	registering_trial_host = 'registering_trial_host',
	host_game_unverified = 'host_game_unverified',
	host_setup_game_structure = 'host_setup_game_structure',
	host_game_setup = 'host_game_setup', 
	host_in_game = 'host_in_game',
	host_finished = 'host_finished',
	connection_closed = 'connection_closed',
	reconnected = 'reconnected',
	restore_state = 'restore_state'
}

function add_standard_player_message_fields(event: AnyEventObject, context: PlayerContext) {
	return {
		type: event.type,
		detail: { 
			...event.detail,
			player_name: context.player_name, 
			player_secret: context.player_secret,
			game_name: context.game_name,
			game_code: context.game_code,
		}
	}
}

function add_standard_host_message_fields(event: AnyEventObject, context: PlayerContext) {
	return {
		type: event.type,
		detail: { 
			...event.detail,
			host_secret: context.host_secret,
			game_name: context.game_name,
			game_code: context.game_code,
		}
	}
}

export const playerMachine = setup({
	types: {
		context: {} as PlayerContext,
	},
	actors: {
		websocketCallback
	},
	actions: {
		log_error: ({ event, context }) => {
			const error = {
				error: 'event_error',
				detail: {
					event: event,
					context,
				}
			}
			console.log(error)
		},
		event_error: ({ event, self }) => {
			toast.error(`event [${event.type}] not valid in state [${self.getSnapshot().value}]`)
		},
		publish_error: ({ event }) => {
			toast.error(`${event.type}, ${event.detail?.message}`)
		},
		init_host: assign({
			host_secret: ({ event }) => event.detail.host_secret,
			host_email: ({ event }) => event.detail.host_email,
			teams_enabled: ({ event }) => event.detail.teams_enabled,
			questions_and_rounds_enabled: ({ event }) => event.detail.questions_and_rounds_enabled,
			is_trial:  ({ event }) => event.detail.is_trial,
			game_name: ({ event }) => event.detail.game_name,
			game_code: ({ event }) => event.detail.game_code,
		}),
		toggle_trial: assign({
			is_trial:  ({ context }) => !context.is_trial,
		}),
		update_game_state: assign({
			players: ({ event }) => (event as Game_Update_Event).detail.players,
			teams: ({ event }) => (event as Game_Update_Event).detail.teams,
			is_trial: ({ event }) => (event as Game_Update_Event).detail.is_trial,
			whos_buzzer_is_active: ({ event }) => (event as Game_Update_Event).detail.whos_buzzer_is_active,
			round_num: ({ event }) => (event as Game_Update_Event).detail.current_round_index,
			question_num: ({ event }) => (event as Game_Update_Event).detail.current_question_index,
			questions_and_rounds_enabled: ({ event }) => (event as Game_Update_Event).detail.questions_and_rounds_enabled,
			teams_enabled: ({ event }) => (event as Game_Update_Event).detail.teams_enabled,
		}),
		update_host_state: assign({
			players: ({ event }) => (event as Host_Update_Event).detail.players,
			teams: ({ event }) => (event as Host_Update_Event).detail.teams,
			is_trial: ({ event }) => (event as Game_Update_Event).detail.is_trial,
			whos_buzzer_is_active: ({ event }) => (event as Host_Update_Event).detail.whos_buzzer_is_active,
			game_structure: ({ event }) => (event as Host_Update_Event).detail.game_structure,
			round_num: ({ event }) => (event as Host_Update_Event).detail.current_round_index,
			question_num: ({ event }) => (event as Host_Update_Event).detail.current_question_index,
			questions_and_rounds_enabled: ({ event }) => (event as Game_Update_Event).detail.questions_and_rounds_enabled,
			teams_enabled: ({ event }) => (event as Game_Update_Event).detail.teams_enabled,
		}),
		update_game_structure: assign({
			game_structure: ({ event }) => (event as Game_Structure_Update_Event).detail.game_structure,
			round_num: ({ event }) => (event as Game_Structure_Update_Event).detail.game_structure.current_round_index,
			question_num: ({ event }) => (event as Game_Structure_Update_Event).detail.game_structure.current_question_index,
		}),
		update_test_buzzer: ({ event }) => {
			// Bypass the react render cycle for maximum instant user responsiveness
			const player_item = document.querySelector(`[data-player-item="${event.detail.player_name}"]`)
			if (player_item) {
				player_item.classList.add('buzzed')
				setTimeout(() => player_item.classList.remove('buzzed'), 500)
			}
		},
		player_registered: assign({
			player_name: ({ event }) => event.detail.player_name,
			player_secret: ({ event }) => event.detail.player_secret,
			game_name: ({ event }) => event.detail.game_name,
			game_code: ({ event }) => event.detail.game_code,
		}),
		handle_player_buzzed: assign({
			whos_buzzer_is_active: ({ event }) => event.detail.whos_buzzer_is_active
		}),
		reset_context: assign({
			player_name: () => '',
			game_name: () => '',
			game_code: () => '',
			player_secret: () => '',
			host_secret: () => '',
			players: () => ({
				map: {},
				list: []
			}),
			teams: () => ({
				map: {},
				list: []
			}),
			game_structure: () => ({
				rounds: [],
				current_question_index: -1,
				current_round_index: -1,
			}),
			whos_buzzer_is_active: () => undefined,
		}),
		clear_game_code_hash: () => {
			window.location.hash = ''
		},
		swap_quiz_questions: assign({
			game_structure: swap_quiz_questions_action
		}),
		swap_rounds: assign({
			game_structure: swap_rounds_action
		}),
		set_trial_mode: assign({
			is_trial: true
		}),
		set_non_trial_mode: assign({
			is_trial: false
		}),
		set_round_name: assign({
			game_structure: set_round_name_action
		}),
		set_question_text: assign({
			game_structure: set_question_text_action
		}),
		set_answer_text: assign({
			game_structure: set_answer_text_action
		}),
		set_points: assign({
			game_structure: set_points_action
		}),
		set_question_deduct_points: assign({
			game_structure: set_question_deduct_points
		}),
	},
	guards: {
		has_player_details: ({ event }) => !!event.detail.player_name && !!event.detail.game_code,
		has_register_host_details: ({ event }) => !!event.detail.game_name && (event.detail.is_trial || !!event.detail.host_email),
		has_register_trial_host_details: ({ event }) => !!event.detail.game_name,
		is_valid_team: ({ event, context }) => event.detail.team_name && !context.teams.map[event.detail.team_name],
		players_are_all_in_teams: ({ context }) => players_are_all_in_teams(context),
		no_host_or_player_secret: ({ context }) => !context.player_secret && !context.host_secret,
		restore_host_game_unverified: ({ event, context }) => event.detail?.state === Server_State_Name.unverified && !!context.host_secret,
		restore_host_game_setup: ({ event, context }) => event.detail?.state === Server_State_Name.pre_game_setup && !!context.host_secret,
		restore_player_game_setup: ({ event, context }) => event.detail?.state === Server_State_Name.pre_game_setup && !!context.player_secret,
		restore_player_in_game: ({ event, context }) => event.detail?.state === Server_State_Name.in_play && !!context.player_secret,
		restore_host_in_game: ({ event, context }) => event.detail?.state === Server_State_Name.in_play && !!context.host_secret,
		restore_host_finished: ({ event, context }) => event.detail?.state === Server_State_Name.finished && !!context.host_secret,
		restore_player_finished: ({ event, context }) => event.detail?.state === Server_State_Name.finished && !!context.player_secret,
	}

}).createMachine({
	initial: Client_State_Name.splash,
	invoke: {
		id: 'websocketCallback',
		src: 'websocketCallback'
	},
	context: {
		player_name: '',
		game_name: '',
		is_trial: true,
		game_code: '',
		player_secret: '',
		host_secret: '',
		host_email: '',
		players: {
			map: {},
			list: []
		},
		teams: {
			map: {},
			list: []
		},
		teams_enabled: false,
		questions_and_rounds_enabled: false,
		game_structure: {
			rounds: [],
			current_round_index: -1,
			current_question_index: -1,
		},
		whos_buzzer_is_active: '',
		question_num: -1,
		round_num: -1,
	},
	on: {
		[Server_Event_Name.game_expired]: {
			actions: ['reset_context', 'clear_game_code_hash']
		},
		'*': {
			actions: ['event_error']
		},
	},
	states: {
		[Client_State_Name.splash]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Client_Event_Name.start_player_registration]: {
					target: Client_State_Name.connecting_player
				},
				[Client_Event_Name.start_host_trial_registration]: {
					actions: ['set_trial_mode'],
					target: Client_State_Name.connecting_host
				},
				[Client_Event_Name.start_host_registration]: {
					actions: ['set_non_trial_mode'],
					target: Client_State_Name.connecting_host
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.splash
				},
			}
		},
		[Client_State_Name.connecting_player]: {
			entry: sendTo('websocketCallback', () => ({ type: Websocket_Event_Names.connect_websocket })),
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Websocket_Event_Names.websocket_opened]: {
					target: Client_State_Name.registering_player
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.splash
				},
			}
		},
		[Client_State_Name.connection_closed]: {
			on: {
				[Client_Event_Name.navigate_back]: {
					target: 'splash'
				},
				[Client_Event_Name.reset_game]: {
					target: 'splash'
				},
				[Websocket_Event_Names.connect_websocket]: {
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Websocket_Event_Names.websocket_opened]: {
					target: Client_State_Name.reconnected
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
			}
		},
		[Client_State_Name.reconnected]: {
			entry: sendTo('websocketCallback', ({ context }) => {
				if (context.host_secret) {
					return add_standard_host_message_fields({ type: Host_Event_Name.get_host_status}, context)
				}
				if (context.player_secret) {
					return add_standard_player_message_fields({ type: Client_Event_Name.player_reconnect}, context)
				}
			}),
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state',
					target: Client_State_Name.restore_state,
				},
				[Server_Event_Name.host_update]: {
					actions: 'update_host_state',
					target: Client_State_Name.restore_state,
				},
				[Server_Event_Name.game_doesnt_exist]: {
					actions: ['reset_context', 'clear_game_code_hash'],
					target: Client_State_Name.splash,
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed,
				},
			}
		},
		[Client_State_Name.restore_state]: {
			always: [
				{ guard: 'restore_host_game_unverified', target: Client_State_Name.host_game_unverified, },
				{ guard: 'restore_host_game_setup', target: Client_State_Name.host_game_setup, },
				{ guard: 'restore_player_game_setup', target: Client_State_Name.player_game_setup, },
				{ guard: 'restore_host_in_game', target: Client_State_Name.host_in_game, },
				{ guard: 'restore_player_in_game', target: Client_State_Name.player_in_game, },
				{ guard: 'restore_host_finished', target: Client_State_Name.host_finished, },
				{ guard: 'restore_player_finished', target: Client_State_Name.player_finished, },
				{ guard: 'no_host_or_player_secret', target: Client_State_Name.splash },
			],
			on: {
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed,
				},
			}
		},
		[Client_State_Name.registering_player]: {
			on: {
				[Client_Event_Name.navigate_back]: {
					target: Client_State_Name.splash
				},
				[Client_Event_Name.register_player]: {
					guard: 'has_player_details',
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				invalid_message: {
					actions: 'publish_error'
				},
				[Server_Event_Name.player_registered]: {
					actions: 'player_registered',
					target: Client_State_Name.player_game_setup
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Websocket_Event_Names.websocket_opened]: {
				},
			}
		},
		[Client_State_Name.player_game_setup]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Client_Event_Name.join_team]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_player_message_fields(event, context))
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state'
				},
				[Client_Event_Name.test_buzz]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_player_message_fields(event, context))
				},
				[Server_Event_Name.game_started]: {
					target: Client_State_Name.player_in_game
				},
				[Client_Event_Name.get_player_status]: {
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Server_Event_Name.game_doesnt_exist]: {
					target: Client_State_Name.splash
				},
				[Client_Event_Name.quit]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_player_message_fields(event, context))
				},
				[Server_Event_Name.player_removed]: {
					target: Client_State_Name.splash
				},
				[Server_Event_Name.buzzer_test]: {
					actions: 'update_test_buzzer'
				}
				
			}
		},
		[Client_State_Name.player_in_game]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Client_Event_Name.buzz]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_player_message_fields(event, context)),
					target: Client_State_Name.player_in_game
				},
				[Server_Event_Name.player_buzzed]: {
					actions: 'handle_player_buzzed'
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state'
				},
				[Client_Event_Name.leave_game]: {
					target: Client_State_Name.splash
				},
				[Client_Event_Name.get_player_status]: {
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Server_Event_Name.game_doesnt_exist]: {
					target: Client_State_Name.splash
				},
				[Server_Event_Name.game_in_setup]: {
					target: Client_State_Name.player_game_setup
				},
				[Server_Event_Name.game_finished]: {
					target: Client_State_Name.player_finished
				}
			},
		},
		[Client_State_Name.connecting_host]: {
			entry: sendTo('websocketCallback', () => ({ type: Websocket_Event_Names.connect_websocket })),
			on: {
				[Websocket_Event_Names.websocket_opened]: {
					target: Client_State_Name.registering_host
				}
			}
		},
		[Client_State_Name.registering_host]: {
			on: {
				[Server_Event_Name.game_in_setup]: [
					{
						guard: ({ context }) => context.questions_and_rounds_enabled === true,
						target: Client_State_Name.host_setup_game_structure
					},
					{
						target: Client_State_Name.host_game_setup
					},
				],
				[Client_Event_Name.navigate_back]: {
					target: Client_State_Name.splash
				},
				[Client_Event_Name.toggle_trial]: {
					actions: 'toggle_trial'
				},
				[Client_Event_Name.register_trial_host]: {
					guard: 'has_register_trial_host_details',
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Client_Event_Name.register_host]: {
					guard: 'has_register_host_details',
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Server_Event_Name.invalid_message]: {
					actions: 'publish_error'
				},
				[Server_Event_Name.host_registered]: {
					actions: 'init_host'
				},
				[Server_Event_Name.game_setup_unverified]: {
					target: Client_State_Name.host_game_unverified
				},
				[Host_Event_Name.get_host_status]: {
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Server_Event_Name.game_doesnt_exist]: {
					target: Client_State_Name.splash
				},

			}
		},
		[Client_State_Name.host_game_unverified]: {
			on: {
				[Server_Event_Name.game_in_setup]: [
					{
						guard: ({ context }) => context.questions_and_rounds_enabled === true,
						target: Client_State_Name.host_setup_game_structure
					},
					{
						guard: ({ context }) => context.questions_and_rounds_enabled === false,
						target: Client_State_Name.host_game_setup
					},
				],
				[Client_Event_Name.cancel_game]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_closed]: {
					target: Client_State_Name.splash
				},
				[Client_Event_Name.navigate_back]: {
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
			}
		},
		[Client_State_Name.host_setup_game_structure]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Client_Event_Name.cancel_game]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_finished]: {
					target: Client_State_Name.host_finished
				},
				[Host_Event_Name.update_game_structure]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_structure_update]: {
					actions: 'update_game_structure'
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state'
				},
				[Host_Event_Name.add_round]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.add_question]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.remove_round]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.remove_question]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.swap_questions]: {
					actions: 'swap_quiz_questions'
				},
				[Host_Event_Name.swap_rounds]: {
					actions: 'swap_rounds'
				},
				[Host_Event_Name.set_round_name]: {
					actions: 'set_round_name'
				},
				[Host_Event_Name.set_question_text]: {
					actions: 'set_question_text'
				},
				[Host_Event_Name.set_answer_text]: {
					actions: 'set_answer_text'
				},
				[Host_Event_Name.set_question_points]: {
					actions: 'set_points'
				},
				[Host_Event_Name.set_question_deduct_points]: {
					actions: 'set_question_deduct_points'
				},
				[Host_Event_Name.setup_game]: {
					target: Client_State_Name.host_game_setup
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
			}
		},
		[Client_State_Name.host_game_setup]: {
			on: {
				[Client_Event_Name.navigate_back]: [
					{
						guard: ({ context }) => context.questions_and_rounds_enabled === true,
						target: Client_State_Name.host_setup_game_structure
					},
					{
						guard: ({ context }) => context.questions_and_rounds_enabled === false,
					}

				],
				[Host_Event_Name.setup_game_structure]: {
					guard: ({ context }) => context.questions_and_rounds_enabled === true,
					target: Client_State_Name.host_setup_game_structure
				},
				[Client_Event_Name.cancel_game]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_finished]: {
					target: Client_State_Name.host_finished
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state'
				},
				[Client_Event_Name.add_team]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},				
				[Host_Event_Name.remove_team]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.remove_player]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Client_Event_Name.start_game]: {
					guard: 'players_are_all_in_teams',
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_started]: {
					target: Client_State_Name.host_in_game
				},
				[Host_Event_Name.get_host_status]: {
					actions: sendTo('websocketCallback', ({ event }) => event)
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Server_Event_Name.game_doesnt_exist]: {
					actions: ['reset_context', 'clear_game_code_hash'],
					target: Client_State_Name.splash
				},
				[Server_Event_Name.buzzer_test]: {
					actions: 'update_test_buzzer'
				}
			}
		},
		[Client_State_Name.host_in_game]: {
			on: {
				[Server_Event_Name.player_buzzed]: {
					actions: 'handle_player_buzzed'
				},
				[Client_Event_Name.clear_buzz]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.get_host_status]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
				[Server_Event_Name.game_doesnt_exist]: {
					target: Client_State_Name.splash
				},
				[Host_Event_Name.finish_game]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_update]: {
					actions: 'update_game_state'
				},
				[Server_Event_Name.game_finished]: {
					target: Client_State_Name.host_finished
				},
				[Host_Event_Name.set_score]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.set_player_score]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.answer_is_correct]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.answer_is_incorrect]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Host_Event_Name.return_to_setup]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_in_setup]: {
					target: Client_State_Name.host_game_setup
				},
				[Client_Event_Name.navigate_back]: {
					actions: sendTo('websocketCallback', ({ context }) => add_standard_host_message_fields({ type: Host_Event_Name.return_to_setup}, context))
				},
			}
		},
		[Client_State_Name.host_finished]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Host_Event_Name.close_game]: {
					actions: sendTo('websocketCallback', ({ event, context }) => add_standard_host_message_fields(event, context))
				},
				[Server_Event_Name.game_closed]: {
					actions: [ 'reset_context', 'clear_game_code_hash' ],
					target: 'splash'
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
			}
		},
		[Client_State_Name.player_finished]: {
			on: {
				[Client_Event_Name.navigate_back]: {
				},
				[Server_Event_Name.game_closed]: {
					actions: [ 'reset_context', 'clear_game_code_hash'],
					target: 'splash'
				},
				[Websocket_Event_Names.websocket_closed]: {
					target: Client_State_Name.connection_closed
				},
			}
		},
	},
});
	
